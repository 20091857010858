*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --background: 161 0% 95%;
  --foreground: 161 0% 0%;
  --card: 161 0% 90%;
  --card-foreground: 161 0% 10%;
  --popover: 161 0% 95%;
  --popover-foreground: 161 95% 0%;
  --primary: 161 100% 62%;
  --primary-foreground: 0 0% 0%;
  --secondary: 161 10% 70%;
  --secondary-foreground: 0 0% 0%;
  --muted: 123 10% 85%;
  --muted-foreground: 161 0% 35%;
  --accent: 123 10% 80%;
  --accent-foreground: 161 0% 10%;
  --destructive: 0 50% 30%;
  --destructive-foreground: 161 0% 90%;
  --border: 161 20% 50%;
  --input: 161 20% 18%;
  --ring: 161 100% 62%;
  --radius: .75rem;
}

.dark {
  --background: 161 10% 5%;
  --foreground: 161 0% 90%;
  --card: 161 0% 0%;
  --card-foreground: 161 0% 90%;
  --popover: 161 10% 5%;
  --popover-foreground: 161 0% 90%;
  --primary: 161 100% 62%;
  --primary-foreground: 0 0% 0%;
  --secondary: 161 10% 10%;
  --secondary-foreground: 0 0% 100%;
  --muted: 123 10% 15%;
  --muted-foreground: 161 0% 60%;
  --accent: 123 10% 15%;
  --accent-foreground: 161 0% 90%;
  --destructive: 0 50% 30%;
  --destructive-foreground: 161 0% 90%;
  --border: 161 0% 16%;
  --input: 161 20% 18%;
  --ring: 161 100% 62%;
  --radius: .75rem;
}

* {
  border-color: hsl(var(--border));
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  font-feature-settings: "rlig" 1, "calt" 1;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (width >= 1400px) {
  .container {
    max-width: 1400px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-inline-start-width: .25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  padding-top: .1875em;
  padding-inline-end: .375em;
  padding-bottom: .1875em;
  border-radius: .3125rem;
  padding-inline-start: .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  padding-top: .857143em;
  padding-inline-end: 1.14286em;
  padding-bottom: .857143em;
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding-inline-start: 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: start;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-inline-start: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .571429em;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-sm {
  font-size: .875rem;
  line-height: 1.71429;
}

.prose-sm :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-inline-start: 1.11111em;
}

.prose-sm :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.prose-sm :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.prose-sm :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.prose-sm :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .142857em;
  padding-inline-end: .357143em;
  padding-bottom: .142857em;
  border-radius: .3125rem;
  padding-inline-start: .357143em;
  font-size: .857143em;
}

.prose-sm :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
}

.prose-sm :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-sm :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-sm :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-inline-start: 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.prose-sm :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-inline-start: 1.57143em;
}

.prose-sm :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.prose-sm :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .428571em;
}

.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(.prose-sm > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  padding-inline-start: 1.57143em;
}

.prose-sm :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.prose-sm :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.prose-sm :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-0, .bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-2 {
  left: .5rem;
}

.left-\[1\.125rem\] {
  left: 1.125rem;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-4 {
  top: 1rem;
}

.top-\[50\%\] {
  top: 50%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[10000\] {
  z-index: 10000;
}

.z-\[9999\] {
  z-index: 9999;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.\!m-0 {
  margin: 0 !important;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[-1px\] {
  margin-left: -1px;
  margin-right: -1px;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[-2px\] {
  margin-left: -2px;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-\[-4px\] {
  margin-top: -4px;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[40px\] {
  height: 40px;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[40vh\] {
  max-height: 40vh;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.w-10 {
  width: 2.5rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[calc\(100\%-2\.25rem\)\] {
  width: calc(100% - 2.25rem);
}

.w-full {
  width: 100%;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[12rem\] {
  min-width: 12rem;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[3\] {
  flex: 3;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.caption-bottom {
  caption-side: bottom;
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.\!select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-between {
  align-content: space-between;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[-8px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8px * var(--tw-space-x-reverse));
  margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: calc(var(--radius)  - 2px);
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: calc(var(--radius)  - 4px);
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-input {
  border-color: hsl(var(--input));
}

.border-primary {
  border-color: hsl(var(--primary));
}

.border-transparent {
  border-color: #0000;
}

.bg-background {
  background-color: hsl(var(--background));
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-black\/90 {
  background-color: #000000e6;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-border {
  background-color: hsl(var(--border));
}

.bg-card {
  background-color: hsl(var(--card));
}

.bg-destructive {
  background-color: hsl(var(--destructive));
}

.bg-green-800\/20 {
  background-color: #16653433;
}

.bg-green-950\/50 {
  background-color: #052e1680;
}

.bg-muted {
  background-color: hsl(var(--muted));
}

.bg-muted\/50 {
  background-color: hsl(var(--muted) / .5);
}

.bg-popover {
  background-color: hsl(var(--popover));
}

.bg-primary {
  background-color: hsl(var(--primary));
}

.bg-red-950\/60 {
  background-color: #450a0a99;
}

.bg-secondary {
  background-color: hsl(var(--secondary));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.bg-\[image\:radial-gradient\(75\%_100\%_at_50\%_0\%\,rgba\(56\,189\,248\,0\.6\)_0\%\,rgba\(56\,189\,248\,0\)_75\%\)\] {
  background-image: radial-gradient(75% 100% at 50% 0, #38bdf899 0%, #38bdf800 75%);
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-emerald-400\/0 {
  --tw-gradient-from: #34d39900 var(--tw-gradient-from-position);
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-emerald-400\/90 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #34d399e6 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-emerald-400\/0 {
  --tw-gradient-to: #34d39900 var(--tw-gradient-to-position);
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-px {
  padding: 1px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-card-foreground {
  color: hsl(var(--card-foreground));
}

.text-current {
  color: currentColor;
}

.text-destructive-foreground {
  color: hsl(var(--destructive-foreground));
}

.text-foreground {
  color: hsl(var(--foreground));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-popover-foreground {
  color: hsl(var(--popover-foreground));
}

.text-primary {
  color: hsl(var(--primary));
}

.text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-secondary-foreground {
  color: hsl(var(--secondary-foreground));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-zinc-900 {
  --tw-shadow-color: #18181b;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-white\/10 {
  --tw-ring-color: #ffffff1a;
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.animate-in {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.fade-in-0 {
  --tw-enter-opacity: 0;
}

.zoom-in-95 {
  --tw-enter-scale: .95;
}

.duration-200 {
  animation-duration: .2s;
}

.duration-500 {
  animation-duration: .5s;
}

.ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.running {
  animation-play-state: running;
}

.\[mask-repeat\:no-repeat\] {
  mask-repeat: no-repeat;
}

.\[mask-size\:40px\] {
  mask-size: 40px;
}

.\[transform-origin\:var\(--radix-hover-card-content-transform-origin\)\] {
  transform-origin: var(--radix-hover-card-content-transform-origin);
}

* {
  scrollbar-color: #d1d1d180 #4e4e4e33;
  scrollbar-width: thin;
}

.ProseMirror .column-block {
  z-index: 1;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 50px;
  width: 100%;
  padding: 8px 0 14px;
  display: grid;
}

.ProseMirror .column {
  --space-inside: 10px;
  padding: var(--space-inside);
  margin: calc(-1 * var(--space-inside));
  z-index: 1;
  background-color: #141f1b00;
  border-radius: 8px;
  transition: all .2s;
}

.ProseMirror-focused .column {
  background-color: #141f1b66;
}

.quick-input-box .input {
  border: 1px solid red;
  outline-color: #0000 !important;
}

.monaco-editor input {
  outline: none !important;
}

a {
  text-decoration: none !important;
}

.quick-input-box {
  border-color: #0000 !important;
  outline-color: #0000 !important;
  box-shadow: 0 0 8px 2px #33ffbd0d !important;
}

.dialog-shadow {
  box-shadow: 0 0 25px 10px #33ffbd17;
}

@keyframes cursor_animation {
  0% {
    transform: scaleX(1);
  }

  90% {
    color: #000;
    transform: scaleX(1);
  }

  91% {
    color: #0000;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 0;
    color: #0000;
    transform: scaleX(0);
  }
}

@keyframes user-drop {
  from {
    filter: blur(5px);
    opacity: 0;
    border-width: 0;
    transform: scale(1.5);
  }

  to {
    filter: blur();
    opacity: 1;
    border-width: 2px;
    transform: scale(1);
  }
}

.user-drop {
  animation: .4s ease-out user-drop;
}

.collaboration-cursor__label {
  text-align: center;
  transform-origin: 0 100%;
  backdrop-filter: blur(6px);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  color: #000;
  opacity: 1;
  z-index: 99999;
  border-radius: 6px;
  width: fit-content;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 700;
  animation: 3s cursor_animation;
  display: block;
  position: absolute;
  top: 0;
  left: -120px;
  overflow: hidden;
  transform: scaleX(0);
}

@keyframes tag-in {
  0% {
    max-height: 0;
    transition: all;
    transform: scaleY(0);
  }

  100% {
    max-height: 50px;
    transition: all;
    transform: scaleY(1);
  }
}

@keyframes tag-in-blur {
  0% {
    filter: blur(5px) saturate(0);
    max-height: 0;
    padding: 0 8px;
    font-size: 10px;
    transition: all;
    transform: scaleY(0);
  }

  100% {
    filter: blur() saturate();
    max-height: 50px;
    padding: 4px 8px;
    transition: all;
    transform: scaleY(1);
  }
}

.tag-in {
  transform-origin: 0 0;
  animation: .2s ease-out tag-in;
}

.tag-in-blur, .ai-tag {
  transform-origin: 0 0;
  animation: .4s tag-in-blur;
}

.ai-tag {
  opacity: .9;
  color: #3b8fff;
  cursor: pointer;
  background: #1277fe26;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 450;
  line-height: 16px;
  transition: all .2s;
  display: flex;
}

.ai-tag .divider {
  background: #3683e7a6;
  width: 1px;
  height: 14px;
  margin-left: 3px;
  transition: all .3s;
}

.ai-tag:hover {
  opacity: 1;
  background: #1278fe16;
  gap: 4px;
}

.ai-tag:hover .divider {
  opacity: .5;
  margin-right: 2px;
}

.mode-view .tiptap * {
  user-select: revert;
}

@media (width >= 640px) {
  .sm\:prose-base {
    font-size: 1rem;
    line-height: 1.75;
  }

  .sm\:prose-base :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .sm\:prose-base :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  .sm\:prose-base :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-inline-start: 1em;
  }

  .sm\:prose-base :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: #f9fafb;
    margin-top: .1em;
    margin-bottom: .5em;
    margin-left: -.1em;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.11111;
  }

  .sm\:prose-base :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: #f9fafb;
    margin-top: .1em;
    margin-bottom: .5em;
    margin-left: -.1em;
    font-size: 1.5em;
    line-height: 1.33333;
  }

  .sm\:prose-base :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: #f9fafb;
    margin-top: .1em;
    margin-bottom: .5em;
    margin-left: -.1em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  .sm\:prose-base :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: #f9fafb;
    margin-top: .1em;
    margin-bottom: .5em;
    margin-left: -.1em;
    line-height: 1.5;
  }

  .sm\:prose-base :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .sm\:prose-base :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:prose-base :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .sm\:prose-base :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .1875em;
    padding-inline-end: .375em;
    padding-bottom: .1875em;
    border-radius: .3125rem;
    padding-inline-start: .375em;
    font-size: .875em;
  }

  .sm\:prose-base :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .sm\:prose-base :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .sm\:prose-base :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .857143em;
    padding-inline-end: 1.14286em;
    padding-bottom: .857143em;
    border-radius: .375rem;
    margin-top: 1.71429em;
    margin-bottom: 1.71429em;
    padding-inline-start: 1.14286em;
    font-size: .875em;
    line-height: 1.71429;
  }

  .sm\:prose-base :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-inline-start: 1.625em;
  }

  .sm\:prose-base :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  .sm\:prose-base :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .375em;
  }

  .sm\:prose-base :where(.sm\:prose-base > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .sm\:prose-base :where(.sm\:prose-base > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .sm\:prose-base :where(.sm\:prose-base > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  .sm\:prose-base :where(.sm\:prose-base > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .sm\:prose-base :where(.sm\:prose-base > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  .sm\:prose-base :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .sm\:prose-base :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .sm\:prose-base :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .sm\:prose-base :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .5em;
    padding-inline-start: 1.625em;
  }

  .sm\:prose-base :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .sm\:prose-base :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .sm\:prose-base :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-base :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
    line-height: 1.71429;
  }

  .sm\:prose-base :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: .571429em;
    padding-bottom: .571429em;
    padding-inline-start: .571429em;
  }

  .sm\:prose-base :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .sm\:prose-base :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .sm\:prose-base :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .571429em;
    padding-inline-end: .571429em;
    padding-bottom: .571429em;
    padding-inline-start: .571429em;
  }

  .sm\:prose-base :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .sm\:prose-base :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .sm\:prose-base :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .sm\:prose-base :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:prose-base :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .857143em;
    font-size: .875em;
    line-height: 1.42857;
  }

  .sm\:prose-base :where(.sm\:prose-base > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-base :where(.sm\:prose-base > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .sm\:prose-base {
    color: #d1d5db;
  }
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-transparent::file-selector-button {
  background-color: #0000;
}

.file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.file\:font-medium::file-selector-button {
  font-weight: 500;
}

.placeholder\:text-muted-foreground::placeholder {
  color: hsl(var(--muted-foreground));
}

.hover\:scale-\[1\.2\]:hover {
  --tw-scale-x: 1.2;
  --tw-scale-y: 1.2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-accent:hover {
  background-color: hsl(var(--accent));
}

.hover\:bg-destructive\/80:hover {
  background-color: hsl(var(--destructive) / .8);
}

.hover\:bg-destructive\/90:hover {
  background-color: hsl(var(--destructive) / .9);
}

.hover\:bg-muted\/50:hover {
  background-color: hsl(var(--muted) / .5);
}

.hover\:bg-primary\/80:hover {
  background-color: hsl(var(--primary) / .8);
}

.hover\:bg-primary\/90:hover {
  background-color: hsl(var(--primary) / .9);
}

.hover\:bg-secondary\/80:hover {
  background-color: hsl(var(--secondary) / .8);
}

.hover\:px-8:hover {
  padding-left: 2rem;
  padding-right: 2rem;
}

.hover\:pr-5:hover {
  padding-right: 1.25rem;
}

.hover\:text-accent-foreground:hover {
  color: hsl(var(--accent-foreground));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:bg-accent:focus {
  background-color: hsl(var(--accent));
}

.focus\:text-accent-foreground:focus {
  color: hsl(var(--accent-foreground));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-ring:focus {
  --tw-ring-color: hsl(var(--ring));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-40 {
  opacity: .4;
}

.group.toaster .group-\[\.toaster\]\:border-border {
  border-color: hsl(var(--border));
}

.group.toast .group-\[\.toast\]\:bg-muted {
  background-color: hsl(var(--muted));
}

.group.toast .group-\[\.toast\]\:bg-primary {
  background-color: hsl(var(--primary));
}

.group.toaster .group-\[\.toaster\]\:bg-background {
  background-color: hsl(var(--background));
}

.group.toast .group-\[\.toast\]\:text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.group.toast .group-\[\.toast\]\:text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.group.toaster .group-\[\.toaster\]\:text-foreground {
  color: hsl(var(--foreground));
}

.group.toaster .group-\[\.toaster\]\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:disabled ~ .peer-disabled\:cursor-not-allowed {
  cursor: not-allowed;
}

.peer:disabled ~ .peer-disabled\:opacity-70 {
  opacity: .7;
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"], .data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.data-\[selected\=true\]\:bg-accent[data-selected="true"] {
  background-color: hsl(var(--accent));
}

.data-\[state\=active\]\:bg-background[data-state="active"] {
  background-color: hsl(var(--background));
}

.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  background-color: hsl(var(--primary));
}

.data-\[state\=open\]\:bg-accent[data-state="open"] {
  background-color: hsl(var(--accent));
}

.data-\[state\=open\]\:bg-secondary[data-state="open"] {
  background-color: hsl(var(--secondary));
}

.data-\[state\=selected\]\:bg-muted[data-state="selected"] {
  background-color: hsl(var(--muted));
}

.data-\[selected\=true\]\:text-accent-foreground[data-selected="true"] {
  color: hsl(var(--accent-foreground));
}

.data-\[state\=active\]\:text-foreground[data-state="active"] {
  color: hsl(var(--foreground));
}

.data-\[state\=checked\]\:text-primary-foreground[data-state="checked"] {
  color: hsl(var(--primary-foreground));
}

.data-\[state\=open\]\:text-accent-foreground[data-state="open"] {
  color: hsl(var(--accent-foreground));
}

.data-\[state\=open\]\:text-muted-foreground[data-state="open"] {
  color: hsl(var(--muted-foreground));
}

.data-\[disabled\=true\]\:opacity-50[data-disabled="true"], .data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.data-\[state\=active\]\:shadow[data-state="active"] {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[state\=closed\]\:duration-300[data-state="closed"] {
  transition-duration: .3s;
}

.data-\[state\=open\]\:duration-500[data-state="open"] {
  transition-duration: .5s;
}

.data-\[state\=open\]\:animate-in[data-state="open"] {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.data-\[state\=closed\]\:animate-out[data-state="closed"] {
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  animation-name: exit;
  animation-duration: .15s;
}

.data-\[state\=closed\]\:fade-out-0[data-state="closed"] {
  --tw-exit-opacity: 0;
}

.data-\[state\=open\]\:fade-in-0[data-state="open"] {
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state="closed"] {
  --tw-exit-scale: .95;
}

.data-\[state\=open\]\:zoom-in-95[data-state="open"] {
  --tw-enter-scale: .95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"] {
  --tw-enter-translate-y: -.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side="left"] {
  --tw-enter-translate-x: .5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side="right"] {
  --tw-enter-translate-x: -.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"] {
  --tw-enter-translate-y: .5rem;
}

.data-\[state\=closed\]\:slide-out-to-bottom[data-state="closed"] {
  --tw-exit-translate-y: 100%;
}

.data-\[state\=closed\]\:slide-out-to-left[data-state="closed"] {
  --tw-exit-translate-x: -100%;
}

.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"] {
  --tw-exit-translate-x: -50%;
}

.data-\[state\=closed\]\:slide-out-to-right[data-state="closed"] {
  --tw-exit-translate-x: 100%;
}

.data-\[state\=closed\]\:slide-out-to-top[data-state="closed"] {
  --tw-exit-translate-y: -100%;
}

.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"] {
  --tw-exit-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-bottom[data-state="open"] {
  --tw-enter-translate-y: 100%;
}

.data-\[state\=open\]\:slide-in-from-left[data-state="open"] {
  --tw-enter-translate-x: -100%;
}

.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"] {
  --tw-enter-translate-x: -50%;
}

.data-\[state\=open\]\:slide-in-from-right[data-state="open"] {
  --tw-enter-translate-x: 100%;
}

.data-\[state\=open\]\:slide-in-from-top[data-state="open"] {
  --tw-enter-translate-y: -100%;
}

.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"] {
  --tw-enter-translate-y: -48%;
}

.data-\[state\=closed\]\:duration-300[data-state="closed"] {
  animation-duration: .3s;
}

.data-\[state\=open\]\:duration-500[data-state="open"] {
  animation-duration: .5s;
}

@media (width >= 640px) {
  .sm\:max-w-\[540px\] {
    max-width: 540px;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:rounded-lg {
    border-radius: var(--radius);
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (width >= 768px) {
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:gap-6 {
    gap: 1.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
  }
}

.\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role="checkbox"]) {
  padding-right: 0;
}

.\[\&\>\[role\=checkbox\]\]\:translate-y-\[2px\] > [role="checkbox"] {
  --tw-translate-y: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>tr\]\:last\:border-b-0:last-child > tr {
  border-bottom-width: 0;
}

.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading] {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading] {
  font-size: .75rem;
  line-height: 1rem;
}

.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading] {
  font-weight: 500;
}

.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading] {
  color: hsl(var(--muted-foreground));
}

.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~ [cmdk-group] {
  padding-top: 0;
}

.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg {
  height: 1.25rem;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg {
  width: 1.25rem;
}

.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input] {
  height: 3rem;
}

.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item] {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item] {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg {
  height: 1.25rem;
}

.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg {
  width: 1.25rem;
}

.\[\&_tr\:last-child\]\:border-0 tr:last-child {
  border-width: 0;
}

.\[\&_tr\]\:border-b tr {
  border-bottom-width: 1px;
}
/*# sourceMappingURL=marketing.b7164b64.css.map */
