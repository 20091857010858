@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  :root {
    --background: 161 0% 95%;
    --foreground: 161 0% 0%;
    --card: 161 0% 90%;
    --card-foreground: 161 0% 10%;
    --popover: 161 0% 95%;
    --popover-foreground: 161 95% 0%;
    --primary: 161 100% 62%;
    --primary-foreground: 0 0% 0%;
    --secondary: 161 10% 70%;
    --secondary-foreground: 0 0% 0%;
    --muted: 123 10% 85%;
    --muted-foreground: 161 0% 35%;
    --accent: 123 10% 80%;
    --accent-foreground: 161 0% 10%;
    --destructive: 0 50% 30%;
    --destructive-foreground: 161 0% 90%;
    --border: 161 20% 50%;
    --input: 161 20% 18%;
    --ring: 161 100% 62%;
    --radius: 0.75rem;
  }

  .dark {
    --background: 161 10% 5%;
    --foreground: 161 0% 90%;
    --card: 161 0% 0%;
    --card-foreground: 161 0% 90%;
    --popover: 161 10% 5%;
    --popover-foreground: 161 0% 90%;
    --primary: 161 100% 62%;
    --primary-foreground: 0 0% 0%;
    --secondary: 161 10% 10%;
    --secondary-foreground: 0 0% 100%;
    --muted: 123 10% 15%;
    --muted-foreground: 161 0% 60%;
    --accent: 123 10% 15%;
    --accent-foreground: 161 0% 90%;
    --destructive: 0 50% 30%;
    --destructive-foreground: 161 0% 90%;
    --border: 161 0% 16%;
    --input: 161 20% 18%;
    --ring: 161 100% 62%;
    --radius: 0.75rem;
  }
}

* {
  scrollbar-color: rgba(209, 209, 209, 0.5) rgba(78, 78, 78, 0.2);
  scrollbar-width: thin;
}

.ProseMirror .column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 50px;
  padding: 8px 0 14px;
  z-index: 1;
}

.ProseMirror .column {
  --space-inside: 10px;
  padding: var(--space-inside);
  margin: calc(-1 * var(--space-inside));
  background-color: hsla(158, 22%, 10%, 0);
  border-radius: 8px;
  transition: all 0.2s ease;

  z-index: 1;
}

.ProseMirror-focused .column {
  background-color: hsla(158, 22%, 10%, 0.4);
}

.quick-input-box .input {
  outline-color: transparent!important;
  border: 1px solid red;
}

.monaco-editor input {
  outline: none!important;
}

a {
  text-decoration: none!important;
}

.quick-input-box {
  outline-color: transparent!important;
  border-color: transparent!important;
  box-shadow: 0 0 8px 2px rgba(51, 255, 189, 0.05)!important;
}

.dialog-shadow {
  box-shadow: 0 0 25px 10px rgba(51, 255, 189, 0.09);
}

@keyframes cursor_animation {
  0% {
    transform: scaleX(1);
  }

  90% {
    transform: scaleX(1);
    color: black;
  }

  91% {
    transform: scaleX(1);
    color: transparent;
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    opacity: 0;
    color: transparent;
  }
}

@keyframes user-drop {
  from {
    border-width: 0px;
    transform: scale(1.5);
    filter: blur(5px);
    opacity: 0;
  }

  to {
    transform: scale(1);
    border-width: 2px;
    filter: blur(0px);
    opacity: 1;
  }
}

.user-drop {
  animation: user-drop 0.4s ease-out;
}

.collaboration-cursor__label {
  position: absolute;
  top: 0;
  left: -120px;
  user-select: none;
  display: block;
  text-align: center;
  width: fit-content;
  transform-origin: bottom left;
  transform: scaleX(0);
  backdrop-filter: blur(6px);
  animation: cursor_animation 3s ease;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  color: transparent;
  
  overflow: hidden;
  opacity: 1;
  z-index: 99999;

  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  color: black;
  padding: 2px 6px;
}

@keyframes tag-in {
  0% {
    transform: scaleY(0);
    max-height: 0px;
    transition: all 0s;
  }

  100% {
    transform: scaleY(1);
    max-height: 50px;
    transition: all 0s;
  }
}

@keyframes tag-in-blur {
  0% {
    transform: scaleY(0);
    font-size: 10px;
    max-height: 0px;
    padding: 0 8px;
    filter: blur(5px) saturate(0);
    transition: all 0s;
  }

  100% {
    transform: scaleY(1);
    max-height: 50px;
    padding: 4px 8px;
    filter: blur(0px) saturate(1);
    transition: all 0s;
  }
}

.tag-in {
  animation: tag-in 0.2s ease-out;
  transform-origin: top left;
}

.tag-in-blur, .ai-tag {
  animation: tag-in-blur 0.4s ease;
  transform-origin: top left;
}

.ai-tag {
  opacity: 0.9;
  color: #3b8fff;
  cursor: pointer;
  height: fit-content;
  background: #1277fe26;
  border-radius: 8px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 450;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 8px;
  transition: all .2s;
}

.ai-tag .divider {
  height: 14px;
  width: 1px;
  margin-left: 3px;
  background: #3683e7a6;
  transition: all .3s;
}

.ai-tag:hover {
  opacity: 1;
  gap: 4px;

  background: #1278fe16;
}

.ai-tag:hover .divider {
  margin-right: 2px;
  opacity: 0.5;
}

.mode-view .tiptap * {
  user-select: revert;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}